import * as React from "react";
// Remember that all const are suffix _svg

const play_svg = (
  <svg
    version="1.1"
    id="play-iframe-youtube"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 576 512">
    <path
      className="st0"
      d="M549.7,124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8,64,288,64,288,64S117.2,64,74.6,75.5
	c-23.5,6.3-42,24.9-48.3,48.6c-11.4,42.9-11.4,132.3-11.4,132.3s0,89.4,11.4,132.3c6.3,23.6,24.8,41.5,48.3,47.8
	C117.2,448,288,448,288,448s170.8,0,213.4-11.5c23.5-6.3,42-24.2,48.3-47.8c11.4-42.9,11.4-132.3,11.4-132.3
	S561.1,166.9,549.7,124.1z M232.1,337.6V175.2l142.7,81.2L232.1,337.6L232.1,337.6z"
    />
  </svg>
);

const loading_svg = (
  <svg
    version="1.1"
    id="loading-iframe-youtube"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512">
    <path
      d="M370.7,133.3C339.5,104,298.9,88,255.8,88C178.3,88.1,111.5,141.2,93,214.9c-1.3,5.4-6.1,9.1-11.7,9.1H24.1
	c-7.5,0-13.2-6.8-11.8-14.2C33.9,94.9,134.8,8,256,8c66.4,0,126.8,26.1,171.3,68.7L463,41c15.1-15.1,41-4.4,41,17v134
	c0,13.3-10.7,24-24,24H345.9c-21.4,0-32.1-25.9-17-41L370.7,133.3z M32,296h134.1c21.4,0,32.1,25.9,17,41l-41.8,41.8
	C172.5,408,213.1,424,256.2,424c77.4-0.1,144.3-53.1,162.8-126.8c1.3-5.4,6.1-9.1,11.7-9.1H488c7.5,0,13.2,6.8,11.8,14.2
	C478.1,417.1,377.2,504,256,504c-66.4,0-126.8-26.1-171.3-68.7L49,471c-15.1,15.1-41,4.4-41-17V320C8,306.7,18.7,296,32,296z"
    />
  </svg>
);

export { loading_svg, play_svg };
