import "./dgt.scss";

// import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

const DGT: React.FC = () => {
  return (
    <main className={"o-dgt"}>
      <div className={"c-dgt"}>
        <div className="m-dgt__first">
          <p className={"a-subtitle"}>
            Una campaña para la concienciación sobre la seguridad en carretera para ciclistas y conductores.
          </p>
        </div>
        <div className="m-dgt__right">
          {/*<StaticImage*/}
          {/*  src={"../../assets/images/road-speed.jpeg"}*/}
          {/*  alt={"Fotografía de ciclista circulando por carretera nacional junto con turismos"}*/}
          {/*/>*/}
          {/*<p>*/}
          {/*  Movistar con el apoyo y la colaboración de la Dirección General de Tráfico (DGT), */}
          {/*  el Consejo Superior de Deportes (CSD), Radio Televisión Española (RTVE), */}
          {/*  La Vuelta a España y la Real Federación Española de Ciclismo (RFEC) promueven #RodamosJuntos.*/}
          {/*</p>*/}
        </div>
      </div>
    </main>
  );
};

export default DGT;
