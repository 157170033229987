import "./iframe-youtube.scss";

import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { PropsWithChildren, Suspense, useEffect, useRef, useState } from "react";

import { loading_svg, play_svg } from "./elements_iframe-youtube";

type PropsIframeYoutube = PropsWithChildren<{
  src: string;
  title: string;
}>;

const enum StatusVideo {
  FACADE,
  LOADING,
  LOADED,
  FAILED,
}

const IframeYoutube: React.FC<PropsIframeYoutube> = (props: PropsIframeYoutube) => {
  const [statusVideo, setStatusVideo] = useState<StatusVideo>(StatusVideo.FACADE);
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const finishLoading = () => {
    setStatusVideo(StatusVideo.LOADED);
  };

  useEffect(() => {
    setStatusVideo(StatusVideo.LOADING);
  }, []);

  return (
    <div
      className="embed-iframe"
      ref={container}>
      {statusVideo > StatusVideo.FACADE && (
        <Suspense fallback={null}>
          <iframe
            className={statusVideo === StatusVideo.LOADED ? "visibility is-z2" : "noVisibility"}
            loading="lazy"
            width="560"
            height="315"
            frameBorder="0"
            /* eslint-disable-next-line max-len */
            src={`https://www.youtube.com/embed/${props.src}?controls=0?&autoplay=1&mute=1&rel=0`}
            title={props.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            onLoad={finishLoading}
            allowFullScreen></iframe>
        </Suspense>
      )}
      {statusVideo < StatusVideo.LOADED && (
        <div className="m-iframe-youtube__status">
          {statusVideo === StatusVideo.FACADE && <>{play_svg}</>}
          {statusVideo === StatusVideo.LOADING && <>{loading_svg}</>}
        </div>
      )}
      <div
        className="m-iframe-youtube__thumbnail"
        onClick={() => {
          setStatusVideo(StatusVideo.LOADING);
        }}>
        <StaticImage
          src={"./thumbnail-video.jpg"}
          width={1200}
          alt={`Miniatura del video ${props.title}`}
        />
      </div>
    </div>
  );
};

export default IframeYoutube;
