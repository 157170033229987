import "../styles/layouts/home.scss";

import { graphql, PageProps } from "gatsby";
import React from "react";

import { Tweet } from "../api/twitter";
import DGT from "../components/DGT/DGT";
import Hero from "../components/Hero/Hero";
import Layout from "../components/Layout/layout";
import { SEO } from "../components/SEO/SEO";
import Gallery from "../components/Slider/Gallery/Gallery";
import Tweets from "../components/Slider/Twitter/Tweets";
import YouAre from "../components/YouAre/YouAre";

const IndexPage = ({ data }: PageProps<Queries.IndexPageQuery, [], [], Tweet[]>) => {
  return (
    <Layout>
      <div className="l-home">
        <Hero />
        <DGT />
        <YouAre />
        <Gallery />
        <Tweets />
      </div>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => 
<SEO 
  title={"#rodamosjuntos | Soy conductor, soy ciclista"}
  description={
      "Una campaña para la concienciación sobre la seguridad en carretera para ciclistas y conductores"
    }
/>;

export const query = graphql`
  query IndexPage {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

// export async function getServerData(): GetServerDataReturn<Record<any, Tweet[]>> {
//   const res = await fetch(`http://localhost:8000/api/twitter`);
//   return {
//     props: await res.json(),
//   };
// }
