import "./you-are.scss";

import * as React from "react";

import CardPost from "../Blog/CardPost/CardPost";

const YouAre: React.FC = () => {
  return (
    <section className={"o-you-are"}>
      <div className={"c-you-are"}>
        <div>
          <p className={"a-subtitle"}>
            Tú también formas parte de nuestro entrenamiento
            <span className={"highlight"}> y de nuestra vida.</span>
          </p>
        </div>
        <div className="m-your-are__card">
          <CardPost />
        </div>
      </div>
    </section>
  );
};

export default YouAre;
