import "./gallery.scss";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { useEffect, useRef, useState } from "react";

import Controls from "../../Controls/Controls";

interface Photo {
  name: string;
  childImageSharp: { gatsbyImageData: any };
}

const Gallery: React.FC = () => {
  const photos_render: JSX.Element[] = [];
  const sliderGalley = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [is_firstLoad, setFirstLoad] = useState(true);
  const [is_scrolling, setIsScrolling] = useState(true);
  const [selectedPhoto, setSelectedPhoto] = useState<number>(1);
  const [photoAutoSelected, setPhotoAutoSelected] = useState<number>(1);

  // const { next, prev, isCanNext, isCanPrev } = useSlider(sliderGalley);
  const { allPhotos }: { allPhotos: { nodes: Photo[] } } = useStaticQuery(graphql`
    query gallery {
      allPhotos: allFile(filter: { sourceInstanceName: { eq: "photos-gallery" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(width: 400, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  `);

  function getWidthScreen() {
    return window.innerWidth;
  }

  const prev = () => {
    if (is_scrolling) {
      setIsScrolling(false);
    }
    if (selectedPhoto < 1) {
      setSelectedPhoto(allPhotos.nodes.length - 1);
    } else {
      setSelectedPhoto(selectedPhoto - 1);
    }
  };

  const next = () => {
    if (is_scrolling) {
      setIsScrolling(false);
    }
    if (selectedPhoto > allPhotos.nodes.length - 2) {
      setSelectedPhoto(0);
    } else {
      setSelectedPhoto(selectedPhoto + 1);
    }
  };

  useEffect(() => {
    if (is_firstLoad) {
      setFirstLoad(false);
      if (getWidthScreen() < 890) {
        setSelectedPhoto(0);
      }
      return;
    }
    let offsetLeft = 150;
    if (getWidthScreen() < 890) {
      offsetLeft = 20;
    }
    if (!is_scrolling) {
      const element = sliderGalley.current.children[selectedPhoto] as HTMLDivElement;
      sliderGalley.current.scrollLeft = element.offsetLeft - offsetLeft;
    }
  }, [selectedPhoto]);

  function has_changeInOneSecond(currentPosition: number) {
    if (currentPosition === photoAutoSelected) {
      setSelectedPhoto(photoAutoSelected);
    }
  }
  useEffect(() => {
    if (is_scrolling) {
      setTimeout(() => {
        has_changeInOneSecond(photoAutoSelected);
      }, 850);
    }
  }, [photoAutoSelected]);

  function normalizeTextWithoutHyphen(text: string): string {
    return text.replace(/[-|_]/g, " ").toLowerCase();
  }

  function handlerIntersect(entries, _observer) {
    if (getWidthScreen() < 590) {
      entries.forEach((entry, _index) => {
        if (entry.isIntersecting) {
          const idHtml: string = entries[_index].target.id;
          const splitIdHtml = idHtml.split("slider-galley__");
          const index: number = parseInt(splitIdHtml[1]);
          setPhotoAutoSelected(index);
        }
      });
    }
  }

  function createObserver() {
    const options = {
      root: sliderGalley.current,
      rootMargin: "20px",
      delay: 500,
      threshold: 0.01,
    };

    const observer = new IntersectionObserver(handlerIntersect, options);
    allPhotos.nodes.forEach((photo, index) => {
      observer.observe(document.getElementById(`slider-galley__${index}`) as HTMLElement);
    });
  }

  useEffect(() => {
    if (is_scrolling) {
      sliderGalley.current.style.scrollSnapType = "x mandatory";
      return;
    }
    sliderGalley.current.style.scrollSnapType = "unset";
  }, [is_scrolling]);

  allPhotos.nodes.forEach((photo: Photo, index) => {
    photos_render.push(
      <div
        id={`slider-galley__${index}`}
        className={"m-gallery__frame"}
        key={index}
        onClick={() => setSelectedPhoto(index)}>
        <GatsbyImage
          className={`a-gallery__photo ${index === selectedPhoto ? "a-gallery__photo--selected" : ""}`}
          alt={`foto de ${normalizeTextWithoutHyphen(photo.name)}`}
          image={photo.childImageSharp.gatsbyImageData}
        />
      </div>
    );
  });

  useEffect(() => {
    createObserver();
  }, []);

  return (
    <section className={"o-gallery"}>
      <div className="c-gallery"></div>
      <div
        ref={sliderGalley}
        className={"scroll-container__native m-gallery__slider"}>
        {photos_render}
      </div>
      <div className="c-gallery">
        <Controls
          prev={prev}
          next={next}
          isCanPrev={true}
          isCanNext={true}
        />
      </div>
    </section>
  );
};

export default Gallery;
