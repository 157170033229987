import "./card-post.scss";

import { Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

import { goPage_svg } from "./elements_card-post";

const CardPost: React.FC = () => {
  const pathToPost = "/consejos-carretera-ciclistas-conductores/";
  return (
    <article
      className={"m-card-post"}
      onClick={() => {
        navigate(pathToPost);
      }}>
      <StaticImage
        className={"m-card__image"}
        imgClassName={"a-card__image"}
        src={"../../../assets/images/post-photo.jpeg"}
        alt={"Fotografía"}
        width={500}
      />
      <div className="m-card-post__info">
        <h3 className={"a-subtitle"}>Consejos para ciclistas al circular en carretera con conductores </h3>
        <p className={"m-card-post__read-more"}>
          <Link to={pathToPost}>{goPage_svg}</Link>
        </p>
      </div>
    </article>
  );
};

export default CardPost;
