import * as React from "react";
// Remember that all const are suffix _svg

const goPage_svg = (
  <svg
    id={"goPage"}
    xmlns="http://www.w3.org/2000/svg"
    width="25.866"
    height="18.831"
    viewBox="0 0 25.866 18.831">
    <path
      id="Trazado_40"
      className={"a-navigation__arrow"}
      data-name="Trazado 40"
      d="M8.536,3.366v21.62a.879.879,0,1,0,1.759,0V3.366l7.293,7.293,1.243-1.243L9.415,0,0,9.416l1.243,1.243Z"
      transform="translate(25.866) rotate(90)"
    />
  </svg>
);

export { goPage_svg };
