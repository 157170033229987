import "./hero.scss";

import * as React from "react";

import IframeYoutube from "../IframeYoutube/IframeYoutube";

const Hero: React.FC = () => {
  return (
    <section className={"o-hero"}>
      <div className={"c-hero"}>
        <h1 className={"a-title"}>
          Soy <span className={"highlight"}> conductor</span>,<br /> soy <span className={"highlight"}>ciclista</span>.{" "}
        </h1>
        <div className="m-hero__video">
          <IframeYoutube
            src={"1yudJQTAo94"}
            title={"#rodamosjuntos"}
          />
        </div>
        <div className="m-hero__footer">
          <p className={"a-subtitle"}>
            La seguridad en la <br /> carretera es tarea de todos.
          </p>
          <p className={"m-hero__hashtag"}>#RodamosJuntos</p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
